import React, { forwardRef } from 'react';

function SvgPlay(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M13.13 9.13c-.056.059-.27.307-.468.51-1.165 1.284-4.204 3.384-5.795 4.025-.242.103-.853.32-1.179.335-.313 0-.61-.072-.895-.218a1.874 1.874 0 01-.795-.904c-.1-.263-.256-1.05-.256-1.064-.156-.861-.242-2.26-.242-3.806 0-1.473.086-2.815.213-3.69.015-.013.17-.991.341-1.326A1.785 1.785 0 015.632 2h.056c.426.015 1.321.395 1.321.409 1.505.642 4.474 2.639 5.668 3.966 0 0 .336.34.482.554.228.306.341.685.341 1.064 0 .423-.128.817-.37 1.138z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgPlay);
export default ForwardRef;
