import React, { forwardRef } from 'react';

function SvgInstagram(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8 0c2.17 0 2.45 0 3.3.05.85.04 1.43.17 1.94.37.53.2.97.48 1.42.92.44.45.72.9.92 1.42.2.5.33 1.09.37 1.94.04.85.05 1.13.05 3.3s0 2.44-.05 3.3a5.87 5.87 0 0 1-.37 1.94c-.2.53-.48.97-.92 1.42-.45.44-.9.72-1.42.92-.5.2-1.09.33-1.94.37-.85.04-1.13.05-3.3.05s-2.45-.01-3.3-.05a5.87 5.87 0 0 1-1.94-.37c-.53-.2-.97-.48-1.42-.92-.44-.45-.72-.9-.92-1.42-.2-.5-.33-1.1-.37-1.94C0 10.44 0 10.17 0 8s0-2.45.05-3.3c.04-.85.17-1.43.37-1.94.2-.53.48-.97.92-1.42.45-.44.9-.72 1.42-.92.5-.2 1.09-.33 1.94-.37C5.55 0 5.83 0 8 0zm0 1.44c-2.14 0-2.39 0-3.23.05-.78.03-1.2.16-1.49.27-.37.15-.64.32-.92.6-.28.28-.45.55-.6.92-.1.28-.24.7-.27 1.49-.04.84-.05 1.1-.05 3.23 0 2.14.01 2.39.05 3.23.03.78.16 1.2.27 1.49.15.37.32.64.6.92.28.28.55.45.92.6.28.1.7.24 1.49.27.84.04 1.1.05 3.23.05 2.14 0 2.39-.01 3.23-.05.78-.03 1.2-.16 1.49-.27.37-.15.64-.32.92-.6.28-.28.45-.55.6-.92.1-.28.24-.7.27-1.49.04-.84.05-1.1.05-3.23 0-2.14-.01-2.39-.05-3.23a4.43 4.43 0 0 0-.27-1.49 2.48 2.48 0 0 0-.6-.92 2.48 2.48 0 0 0-.92-.6c-.28-.1-.7-.24-1.49-.27-.84-.04-1.1-.05-3.23-.05zM8 3.9a4.1 4.1 0 1 1 0 8.22 4.1 4.1 0 0 1 0-8.22zm0 6.78a2.67 2.67 0 1 0 0-5.34 2.67 2.67 0 0 0 0 5.34zm5.23-6.94a.96.96 0 1 1-1.92 0 .96.96 0 0 1 1.92 0z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgInstagram);
export default ForwardRef;
