import React from 'react';
import PropTypes from 'prop-types';

import { HeadingContext } from './HeadingContext';

const propTypes = {
    children: PropTypes.node,
    levelOverride: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};
const defaultProps = {
    children: null,
    levelOverride: '',
};

function HeadingBoundary({ children, levelOverride }) {
    return (
        <HeadingContext.Consumer>
            {(level) => (
                <HeadingContext.Provider
                    value={levelOverride ? parseInt(levelOverride, 10) : level + 1}
                >
                    {children}
                </HeadingContext.Provider>
            )}
        </HeadingContext.Consumer>
    );
}

HeadingBoundary.propTypes = propTypes;
HeadingBoundary.defaultProps = defaultProps;

export { HeadingBoundary };
