import React, { forwardRef } from 'react';

function SvgUser(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8 10.54c3.432 0 6.362.543 6.362 2.72C14.362 15.436 11.45 16 8 16c-3.431 0-6.361-.544-6.361-2.72 0-2.177 2.911-2.74 6.36-2.74zM8 0a4.206 4.206 0 014.21 4.233A4.207 4.207 0 018 8.466a4.207 4.207 0 01-4.208-4.233A4.206 4.206 0 018 0z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgUser);
export default ForwardRef;
