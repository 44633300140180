// extracted by mini-css-extract-plugin
export var textSize0 = "Text-module--textSize0--ywuG0";
export var textSize1 = "Text-module--textSize1--2swqC";
export var textSize2 = "Text-module--textSize2--A-ZE5";
export var textSize3 = "Text-module--textSize3--3njNt";
export var textSize4 = "Text-module--textSize4--3f67u";
export var textSize5 = "Text-module--textSize5--1fPxp";
export var textSize6 = "Text-module--textSize6--1BT-j";
export var textSize7 = "Text-module--textSize7--1-i3E";
export var textSize8 = "Text-module--textSize8--3YBk9";
export var textSize9 = "Text-module--textSize9--VNOnH";
export var textSize10 = "Text-module--textSize10--2aZY6";
export var textSize11 = "Text-module--textSize11--3b3CQ";
export var textSize12 = "Text-module--textSize12--2hglH";
export var textSize13 = "Text-module--textSize13--2vKqR";
export var textEmphasisLow = "Text-module--textEmphasisLow--2P_D3";
export var textEmphasisMedium = "Text-module--textEmphasisMedium--2WP2g";
export var textEmphasisHigh = "Text-module--textEmphasisHigh--na_v_";
export var show = "Text-module--show--11NfW";
export var showFadeInDown = "Text-module--show-fade-in-down--1Z7OV";
export var showFadeInUp = "Text-module--show-fade-in-up--3wwuk";
export var hideFadeOut = "Text-module--hide-fade-out--11grf";