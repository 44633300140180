import React, { forwardRef } from 'react';

function SvgTwitter(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M16 2.91302C15.4121 3.18075 14.7791 3.3618 14.1152 3.4427C14.7932 3.02666 15.3135 2.36698 15.5586 1.58113C14.9238 1.96635 14.2222 2.2466 13.4728 2.39683C12.8755 1.74293 12.0218 1.33459 11.0771 1.33459C9.26548 1.33459 7.79574 2.84176 7.79574 4.70043C7.79574 4.9643 7.82391 5.22047 7.88026 5.46701C5.15208 5.32641 2.73381 3.98778 1.11381 1.94902C0.831132 2.44787 0.669601 3.02666 0.669601 3.64301C0.669601 4.81022 1.24905 5.84067 2.12995 6.4445C1.59277 6.42813 1.08564 6.27501 0.642367 6.02461V6.06603C0.642367 7.69742 1.77402 9.0582 3.27663 9.36637C3.00147 9.44534 2.71128 9.48483 2.41169 9.48483C2.20039 9.48483 1.99378 9.46461 1.79374 9.42512C2.21166 10.7618 3.42314 11.7355 4.86001 11.7615C3.73681 12.6648 2.3206 13.2031 0.783237 13.2031C0.518401 13.2031 0.256383 13.1877 0 13.1569C1.45284 14.1103 3.17896 14.6679 5.03187 14.6679C11.0705 14.6679 14.3715 9.53972 14.3715 5.09143C14.3715 4.94504 14.3687 4.79866 14.3631 4.65517C15.0045 4.18039 15.5614 3.58812 16 2.91302Z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgTwitter);
export default ForwardRef;
