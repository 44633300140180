import React, { forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';

import { ExternalIcon } from 'icons';
import { Text } from '../Text';
import { cx, capitalizeFirstLetter, linkResolver } from 'utility';
import * as styles from './Link.module.scss';

const activeClassName = 'isActive';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    emphasis: PropTypes.oneOf(['', 'low', 'medium', 'high']),
    partiallyActive: PropTypes.bool,
    reverseUnderline: PropTypes.bool,
    showExternalIcon: PropTypes.bool,
    size: PropTypes.oneOf([undefined, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
    to: PropTypes.object,
    typename: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: '',
    emphasis: '',
    partiallyActive: false,
    reverseUnderline: false,
    showExternalIcon: true,
    size: undefined,
    to: {},
    typename: '',
};

function getClasses({ className, emphasis, isInternal, showExternalIcon, reverseUnderline }) {
    const classes = cx(
        className,
        styles['link'],
        emphasis !== '' && styles[`linkEmphasis${capitalizeFirstLetter(emphasis)}`],
        (!isInternal && showExternalIcon) && styles[`linkExternal`],
        reverseUnderline && styles[`linkReverse`],

    );

    return classes || undefined;
}

const Link = forwardRef(({
    children,
    className,
    emphasis,
    reverseUnderline,
    partiallyActive,
    showExternalIcon,
    size,
    to,
    typename,
    ...props
}, ref) => {
    const url = linkResolver()(to);
    const isInternal = /^\/(?!\/)/.test(url);
    const classes = getClasses({ className, emphasis, isInternal, showExternalIcon, reverseUnderline });

    if (!isInternal) {
        return (
            <Text
                as="a"
                className={classes}
                href={url}
                ref={ref}
                rel="noopener noreferrer"
                size={size}
                target="_blank"
                {...props}
            >
                {children}
                {/* {showExternalIcon && (
                    <ExternalIcon className={styles['externalIcon']} />
                )} */}
            </Text>
        );
    }

    return (
        <Text
            activeClassName={activeClassName}
            as={GatsbyLink}
            className={classes}
            partiallyActive={partiallyActive}
            ref={ref}
            size={size}
            to={url}
            {...props}
        >
            {children}
        </Text>
    );
});

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export { Link };
