import React from 'react';
import PropTypes from 'prop-types';

import { DribbbleIcon } from 'icons';
import { InstagramIcon } from 'icons';
import { LinkedInIcon } from 'icons';
import { TwitterIcon } from 'icons';
import { Button } from 'components/Button';
import { Grid } from 'components/Grid';
import { Link } from 'components/Link';
import { Text } from 'components/Text';
import { cx, usePageCarbon } from 'utility';
import * as styles from './Footer.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: '',
    ctaText: '',
    ctaTitle: '',
    ctaUrl: {},
    navLinks: [],
    socialLinks: [],
};

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['footer'],
    );

    return classes || undefined;
}

function Footer({
    className,
    ctaButtonText,
    ctaText,
    ctaTitle,
    ctaUrl,
    navLinks,
    pathname,
    socialLinks,
}) {
    const classes = getClasses({ className });
    const primaryNavLinks = navLinks.filter(({ nav_type }) => nav_type === 'Primary');
    const { co2, isLoading, isError } = usePageCarbon(`${process.env.GATSBY_SITE_URL}${pathname}`);

    return (
        <footer className={classes}>
            <Grid>
                <div className={styles['cta']}>
                    <div className={styles['ctaWrapper']}>
                        <Text as="strong" className={styles['ctaTitle']} size={4}>{ctaTitle}</Text>
                        {!ctaButtonText && (
                            (ctaText && (ctaUrl?.url || ctaUrl?.uid)) && (
                                <Link className={styles['ctaLink']} emphasis="high" partiallyActive={false} showExternalIcon={false} size={8} to={ctaUrl}>{ctaText}</Link>
                            )
                        )}
                        {ctaButtonText && (
                            <>
                                <Text className={styles['ctaText']} emphasis="high" size={9}>{ctaText}</Text>
                                <Button className={styles['ctaButton']} color="secondary" showExternalIcon={false} to={ctaUrl}>{ctaButtonText}</Button>
                            </>
                        )}
                    </div>
                </div>
                <nav className={styles['nav']}>
                    {primaryNavLinks.length > 0 && (
                        <ul className={styles['navList']}>
                            {primaryNavLinks.map(({ nav_link_text, nav_link_url }, index) => {
                                const linkText = nav_link_text?.text;
                                const linkUrl = nav_link_url;

                                return (
                                    <li className={styles['navItem']} key={`primary-footer-nav-item-${index}`}>
                                        <Link className={styles['navLink']} partiallyActive={true} size={1} to={linkUrl}>{linkText}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {socialLinks.length > 0 && (
                        <ul className={styles['navList', 'navListSocial']}>
                            {socialLinks.map(({ social_link_type, social_link_url }, index) => {
                                const linkType = social_link_type?.text;
                                const linkUrl = social_link_url;

                                return (
                                    <li className={styles['navItem']} key={`social-footer-nav-item-${index}`}>
                                        <Button aria-label={linkType} className={styles['navButton']} color="primary" iconOnly={true} style="outline" to={linkUrl}>
                                            {(linkType === 'Dribbble') && (
                                                <DribbbleIcon />
                                            )}
                                            {(linkType === 'Instagram') && (
                                                <InstagramIcon />
                                            )}
                                            {(linkType === 'LinkedIn') && (
                                                <LinkedInIcon />
                                            )}
                                            {(linkType === 'Twitter') && (
                                                <TwitterIcon />
                                            )}
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {(co2) && (
                        <div className={styles['carbon']}>
                            <Text className={styles['carbonLink']} size={1} >This page emitted <Text as="span" emphasis="medium">{co2}g of C02</Text></Text>
                        </div>
                    )}
                </nav>
                <div className={styles['background']}></div>
            </Grid>
        </footer>
    );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export { Footer };
