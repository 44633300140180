import React, { forwardRef } from 'react';

function SvgDribbble(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M11.818 13.666c-.07-.412-.434-2.418-1.33-4.88 2.206-.352 4.117.253 4.258.299a6.834 6.834 0 01-2.928 4.581zM8 14.832a6.805 6.805 0 01-4.353-1.566c.09.074.154.12.154.12s1.31-2.856 5.361-4.268c.015-.006.031-.01.046-.014.962 2.497 1.358 4.59 1.46 5.187A6.809 6.809 0 018 14.832zM1.168 8c0-.072.003-.144.005-.215.122.003 3.497.079 7.015-.974.195.383.383.774.554 1.162-.09.025-.18.052-.27.082-3.686 1.19-5.556 4.508-5.556 4.508l.002.003A6.806 6.806 0 011.168 8zm3.917-6.177a40.86 40.86 0 012.537 3.936c-3.28.872-6.128.837-6.308.834a6.834 6.834 0 013.771-4.77zm1.278-.455v.002l-.021.003.021-.005zm6.15 1.507c-.017.025-.99 1.507-3.57 2.473A34.713 34.713 0 006.39 1.36a6.805 6.805 0 016.123 1.513zm2.318 5.058c-.1-.022-2.412-.52-4.759-.224-.049-.117-.097-.234-.148-.352a20.536 20.536 0 00-.454-.992c2.698-1.101 3.793-2.684 3.806-2.703a6.805 6.805 0 011.555 4.27zm1.007-1.542a8.027 8.027 0 00-1.205-2.863 7.99 7.99 0 00-2.16-2.16A7.977 7.977 0 0011.114.63a8.016 8.016 0 00-8.771 1.715A8.054 8.054 0 00.629 4.886 7.96 7.96 0 000 7.999a7.918 7.918 0 00.63 3.115c.199.474.448.933.737 1.36.286.424.614.82.976 1.184a8.017 8.017 0 002.543 1.713c.483.205.99.36 1.503.465a7.953 7.953 0 003.222 0 7.876 7.876 0 002.862-1.203 8.123 8.123 0 001.184-.975c.362-.364.691-.76.976-1.184a7.985 7.985 0 001.205-6.084z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgDribbble);
export default ForwardRef;
