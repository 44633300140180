import React from 'react';
import PropTypes from 'prop-types';

import { cx } from 'utility';
import * as styles from './Grid.module.scss';

const propTypes = {
    background: PropTypes.bool,
    block: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    highlight: PropTypes.bool,
};

const defaultProps = {
    background: false,
    block: false,
    children: null,
    className: '',
    highlight: false,
};

function getClasses({ className, background, block, highlight }) {
    const classes = cx(
        className,
        styles['container'],
        background && styles['containerBackground'],
        block && styles['containerBlock'],
        highlight && styles['containerHighlight'],
    );

    return classes || undefined;
}

function Grid({
    background,
    block,
    children,
    className,
    highlight,
}) {
    const classes = getClasses({ className, background, block, highlight });

    return (
        <div className={classes}>
            {children}
            {background && (
                <div className={styles['background']}></div>
            )}
        </div>
    );
}

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export { Grid };
