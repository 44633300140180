import React, { forwardRef } from 'react';

function SvgCheck(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M13.655 3.95a1.192 1.192 0 00-1.678 0L6.39 9.537 4.023 7.169a1.192 1.192 0 00-1.678 0c-.46.46-.46 1.204 0 1.678l3.22 3.206c.23.23.527.338.825.338.311 0 .609-.108.839-.338l6.426-6.426c.46-.46.46-1.204 0-1.677z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgCheck);
export default ForwardRef;
