import React from 'react';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utility/htmlSerializer';

import { cx } from 'utility';
import * as styles from './Content.module.scss';

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['content'],
    );

    return classes || undefined;
}

function Content({
    className,
    content,
}) {
    const classes = getClasses({ className });

    return (
        <div className={classes}>
            <RichText
                htmlSerializer={htmlSerializer}
                render={content}
            />
        </div>
    );
}

export { Content };
