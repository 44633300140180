import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const defaultProps = {
    bodyClassName: '',
    lang: 'en',
    locale: 'en-GB',
    meta: [],
    pageDescription: '',
    pageSocialImage: 'https://images.prismic.io/robsimpson/ad9fb4b1-b7ae-4e22-9787-20d2763bbdca_Social+Share.jpg',
    pageTitle: '',
    pageTwitterCard: 'summary_large_image',
    siteAuthor: 'Rob Simpson',
    siteName: 'Rob Simpson',
    siteUrl: 'https://robsimpson.digital',
};

const propTypes = {
    bodyClassName: PropTypes.string,
    lang: PropTypes.string,
    locale: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.string),
    pageDescription: PropTypes.string,
    pageSocialImage: PropTypes.string,
    pageTitle: PropTypes.string,
    pageTwitterCard: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    siteAuthor: PropTypes.string,
    siteName: PropTypes.string,
    siteUrl: PropTypes.string,
};

function SEO({
    bodyClassName,
    lang,
    locale,
    meta,
    pathname,
    pageDescription,
    pageSocialImage,
    pageTitle,
    pageTwitterCard,
    siteAuthor,
    siteName,
    siteUrl,
}) {
    const siteTitle = 'Rob Simpson';
    const metaDescription = pageDescription;
    const metaSocialImage = pageSocialImage ? pageSocialImage : 'https://images.prismic.io/robsimpson/bf0de058-b92f-42d6-ab66-ede3b0c918a4_Social+Share+Home.jpg';
    const metaTitle = `${pageTitle} - ${siteTitle}`;

    const links = [
        {
            href: 'https://p.typekit.net',
            rel: 'preconnect',
        },
        {
            as: 'font',
            crossorigin: '',
            href: 'https://use.typekit.net/af/854e12/00000000000000003b9ad9d8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3',
            rel: 'preload',
            type: 'font/woff2',
        },
        {
            as: 'font',
            crossorigin: '',
            href: 'https://use.typekit.net/af/26af32/00000000000000003b9ada10/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
            rel: 'preload',
            type: 'font/woff2',
        },
    ];

    const scripts = [
        {
            aysnc: true,
            defer: true,
            'data-domain': 'robsimpson.me',
            src: 'https://plausible.io/js/plausible.js',
        },
    ];

    return (
        <Helmet
            htmlAttributes={{ lang }}
            bodyAttributes={{ class: bodyClassName }}
            title={pageTitle}
            defaultTitle={siteTitle}
            titleTemplate={`%s - ${siteTitle}`}
            link={links}
            style={[{
                "cssText": `
                    @import url("https://p.typekit.net/p.css?s=1&k=wlc2vak&ht=tk&f=28000.28056.31643&a=1240669&app=typekit&e=css");

                    @font-face {
                        font-family:"paralucent";
                        src:url("https://use.typekit.net/af/854e12/00000000000000003b9ad9d8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/854e12/00000000000000003b9ad9d8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/854e12/00000000000000003b9ad9d8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
                        font-display:swap;font-style:normal;font-weight:300;
                    }
                    
                    @font-face {
                        font-family:"paralucent";
                        src:url("https://use.typekit.net/af/26af32/00000000000000003b9ada10/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/26af32/00000000000000003b9ada10/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/26af32/00000000000000003b9ada10/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
                        font-display:swap;font-style:normal;font-weight:400;
                    }
                    
                    .tk-paralucent { font-family: "paralucent",sans-serif; }
                    .tk-paralucent-stencil { font-family: "paralucent-stencil",sans-serif; }
                    .tk-paralucent-text { font-family: "paralucent-text",sans-serif; }
                    .tk-paralucent-condensed { font-family: "paralucent-condensed",sans-serif; }
                `
            }]}
            script={scripts}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    name: 'article:author',
                    content: siteAuthor,
                },
                {
                    property: 'og:title',
                    content: metaTitle,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:image',
                    content: metaSocialImage,
                },
                {
                    property: 'og:url',
                    content: `${siteUrl}${pathname}`,
                },
                {
                    property: 'og:locale',
                    content: locale,
                },
                {
                    property: 'og:site_name',
                    content: siteName,
                },
                {
                    name: 'twitter:card',
                    content: pageTwitterCard,
                },
                {
                    name: 'twitter:creator',
                    content: siteAuthor,
                },
                {
                    name: 'twitter:title',
                    content: metaTitle,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    name: 'twitter:image',
                    content: metaSocialImage,
                },
                {
                    name: 'twitter:url',
                    content: `${siteUrl}${pathname}`,
                },
                {
                    name: 'apple-mobile-web-app-status-bar-style',
                    content: 'black-translucent',
                },
            ].concat(meta)}
        />
    );
}

SEO.propTypes = propTypes;
SEO.defaultProps = defaultProps;

export { SEO };
