import React, { forwardRef } from 'react';

function SvgLinkedIn(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M16 15.27h-3.536v-5.113c0-1.338-.553-2.252-1.77-2.252-.93 0-1.448.616-1.688 1.21-.09.214-.077.511-.077.808v5.347H5.426s.045-9.057 0-9.88H8.93v1.55c.207-.678 1.327-1.646 3.113-1.646C14.26 5.294 16 6.715 16 9.776v5.494zM1.883 4.153h-.022C.73 4.153 0 3.398 0 2.44 0 1.464.754.725 1.905.725c1.15 0 1.858.737 1.88 1.713 0 .957-.73 1.715-1.902 1.715zM.403 5.39h3.12v9.881H.402V5.39z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgLinkedIn);
export default ForwardRef;
