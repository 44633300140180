import React, { forwardRef } from 'react';

function SvgPause(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M13.5 3v10a1.002 1.002 0 01-1 1h-2.25a1.001 1.001 0 01-1-1V3a1.001 1.001 0 011-1h2.25a1.001 1.001 0 011 1zM5.75 2H3.5a1.001 1.001 0 00-1 1v10a1.001 1.001 0 001 1h2.25a1.001 1.001 0 001-1V3a1.001 1.001 0 00-1-1z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgPause);
export default ForwardRef;
