import React, { forwardRef } from 'react';

function SvgExternal(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M16 4.536C16 1.824 14.096 0 11.264 0H4.728C1.904 0 0 1.824 0 4.536v6.936C0 14.182 1.904 16 4.728 16h6.536C14.096 16 16 14.183 16 11.472V4.536zM5.46 5.697c0-.328.272-.6.6-.6l4.254-.01c.316 0 .6.282.6.599l-.012 4.254c0 .328-.272.6-.6.6a.63.63 0 01-.424-.176.614.614 0 01-.175-.424l.005-2.8-3.592 3.592a.594.594 0 01-.848 0 .6.6 0 010-.849L8.86 6.291l-2.795.012a.613.613 0 01-.605-.606z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgExternal);
export default ForwardRef;
