const linkResolver = ({ node, key, value } = {}) => doc => {
	const linkType = doc.link_type;

	if (linkType === 'Media' || linkType === 'Web') {
    	return doc.url;
	}
	else if (linkType === 'Document') {
        if (doc.uid) {
            return `/${doc.uid}`;
        } else {
            return '/';
        }
  	}
};

export { linkResolver };
