import React, { forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';

import { ExternalIcon } from 'icons';
import { Text } from '../Text';
import { cx, capitalizeFirstLetter, linkResolver } from 'utility';
import * as styles from './Button.module.scss';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    showExternalIcon: PropTypes.bool,
    iconOnly: PropTypes.bool,
    label: PropTypes.string,
    size: PropTypes.oneOf(['normal', 'small', 'large']),
    style: PropTypes.oneOf(['ghost', 'outline', 'contained']),
    to: PropTypes.object,
    type: PropTypes.oneOf(['', 'button', 'submit', 'reset']),
};

const defaultProps = {
    children: null,
    className: '',
    color: 'primary',
    disabled: false,
    showExternalIcon: true,
    iconOnly: false,
    label: null,
    size: 'normal',
    style: 'contained',
    to: {},
    type: '',
};

function getClasses({ className, color, iconOnly, size, style }) {
    const classes = cx(
        className,
        styles['button'],
        color && styles[`button${capitalizeFirstLetter(color)}`],
        iconOnly && styles[`buttonIconOnly`],
        size && styles[`button${capitalizeFirstLetter(size)}`],
        style && styles[`button${capitalizeFirstLetter(style)}`],
    );

    return classes || undefined;
}

function getFontSize(size) {
    switch (size) {
        case 'small':
            return 0;
        case 'large':
            return 2;
        default:
            return 1;
    }
}

const Button = forwardRef(({
    children,
    className,
    color,
    disabled,
    showExternalIcon,
    iconOnly,
    label,
    size,
    style,
    to,
    type,
    ...props
}, ref) => {
    const classes = getClasses({ className, color, iconOnly, size, style });
    const isButton = Object.keys(to).length === 0 && to.constructor === Object;
    const fontSize = getFontSize(size);

    if (!isButton) {
        const url = linkResolver()(to);
        const isInternal = /^\/(?!\/)/.test(url);

        if (!isInternal) {
            return (
                <Text
                    as="a"
                    aria-label={label}
                    className={classes}
                    href={url}
                    ref={ref}
                    rel="noopener noreferrer"
                    size={fontSize}
                    target="_blank"
                    {...props}
                >
                    {children}
                    {(!iconOnly && showExternalIcon) && (
                        <ExternalIcon />
                    )}
                </Text>
            );
        }

        return (
            <Text
                as={GatsbyLink}
                aria-label={label}
                className={classes}
                ref={ref}
                size={fontSize}
                to={url}
                {...props}
            >
                {children}
            </Text>
        );
    }

    return (
        <Text
            as="button"
            aria-label={label}
            className={classes}
            disabled={disabled}
            ref={ref}
            size={fontSize}
            type={type}
            {...props}
        >
            {children}
        </Text>
    );
});

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export { Button };
