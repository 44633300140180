import React, { forwardRef } from 'react';

function SvgStar(props, svgRef) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            ref={svgRef}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12.733 9.856a.88.88 0 00-.255.776l.711 3.936a.864.864 0 01-.36.864.88.88 0 01-.936.064l-3.542-1.848a.904.904 0 00-.4-.105h-.217a.65.65 0 00-.216.072l-3.543 1.857c-.176.088-.374.119-.568.088a.89.89 0 01-.712-1.017l.712-3.936a.895.895 0 00-.256-.783l-2.888-2.8a.864.864 0 01-.215-.904.898.898 0 01.711-.6l3.975-.577a.89.89 0 00.704-.487L7.19.864a.833.833 0 01.16-.216l.072-.056a.537.537 0 01.129-.104l.087-.032L7.774.4h.337c.3.03.565.21.704.48l1.775 3.576a.89.89 0 00.664.487l3.975.577c.336.048.617.28.728.6a.869.869 0 01-.232.904l-2.992 2.832z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const ForwardRef = forwardRef(SvgStar);
export default ForwardRef;
