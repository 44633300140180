import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';

import { cx } from 'utility';
import * as styles from './Image.module.scss';

const propTypes = {
    className: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    size: PropTypes.oneOfType([
        PropTypes.oneOf(['xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall', 'xxsmall']),
        PropTypes.number,
    ]),
};

const defaultProps = {
    className: '',
    image: {},
    size: 'medium',
};

function getClasses({ className, inView }) {
    const classes = cx(
        className,
        styles['image'],
        inView && styles['inview'],
    );

    return classes || undefined;
}

function getImageUrl(imageUrl, size, quality, type) {
    const imagePath = (type === 'webp') ? `${imageUrl}&fm=webp&lossless=false&q=${quality}`: `${imageUrl}&q=${quality}`;

    switch(size) {
        case 'xxlarge':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w, ${imagePath}&w=1920 1920w, ${imagePath}&w=2560 2560w`;
        case 'xlarge':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w, ${imagePath}&w=1920 1920w`;
        case 'large':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w`;
        case 'medium':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w`;
        case 'small':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w`;
        case 'xsmall':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w`;
        case 'xxsmall':
            return `${imagePath}&w=375 375w`;
        default:
            return `${imagePath}&w=${size} ${size}w`;
    }
}

function getSizes(size) {
    switch(size) {
        case 'xxlarge':
            return '2560px';
        case 'xlarge':
            return '1920px';
        case 'large':
            return '1280px';
        case 'medium':
            return '960px';
        case 'small':
            return '768px';
        case 'xsmall':
            return '480px';
        case 'xxsmall':
            return '375px';
        default:
            return `${size}px`;
    }
}

function Image({
    className,
    image,
    size,
}) {
    const [ref, inView, entry] = useInView({
        threshold: '0.2',
        triggerOnce: true,
    });
    const classes = getClasses({ className, inView });
    const imageAlt = (image.alt) ? image.alt : '';
    const imageHeight = image?.dimensions?.height;
    const imageUrl = image?.url;
    const imageWidth = image?.dimensions?.width;
    const aspectRatio = imageHeight / imageWidth * 100 + '%';
    const extension = imageUrl.split('.').pop().split('?')[0];
    const bitmapImagePath = (extension !== 'svg') ? getImageUrl(imageUrl, size, 75, 'bitmap') : '';
    const webpImagePath = (extension !== 'svg') ? getImageUrl(imageUrl, size, 75, 'webp') : '';
    const sizes = getSizes(size);

    return (
        <>
            {(extension !== 'svg') && (
                <div className={classes} style={{'paddingTop': aspectRatio}} ref={ref}>
                    <picture>
                        <source type="image/webp" srcset={webpImagePath} sizes={`(min-width: ${sizes}) ${sizes}, 100vw`} />
                        <img sizes={`(min-width: ${sizes}) ${sizes}, 100vw`} decoding="async" src={`${imageUrl}&q=65`} srcset={bitmapImagePath} alt={imageAlt} loading="lazy" />
                    </picture>
                </div>
            )}
            {(extension === 'svg') && (
                <img alt={imageAlt} className={classes} height={imageHeight} loading="lazy" src={imageUrl} ref={ref} width={imageWidth} />
            )}
        </>
    );
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export { Image };
