// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--3o64R";
export var background = "Footer-module--background--3X7_e";
export var cta = "Footer-module--cta--2sbJu";
export var ctaWrapper = "Footer-module--ctaWrapper--1ZmLL";
export var ctaTitle = "Footer-module--ctaTitle--3yh6d";
export var ctaLink = "Footer-module--ctaLink--gGOGx";
export var ctaText = "Footer-module--ctaText--3r4eo";
export var nav = "Footer-module--nav--2OLJT";
export var navList = "Footer-module--navList--o0pr8";
export var navListSocial = "Footer-module--navListSocial--1RMt_";
export var navItem = "Footer-module--navItem--3BK2X";
export var navLink = "Footer-module--navLink--3sbSF";
export var carbon = "Footer-module--carbon--2yd7o";
export var show = "Footer-module--show--3Tn2V";
export var showFadeInDown = "Footer-module--show-fade-in-down--2R8mI";
export var showFadeInUp = "Footer-module--show-fade-in-up--1PsmJ";
export var hideFadeOut = "Footer-module--hide-fade-out--2qZXe";