import useSWR from 'swr';

const usePageCarbon = function(pageUrl) {
    const fetcher = (...args) => fetch(...args).then(res => res.json());
    const { data, error } = useSWR(`https://api.websitecarbon.com/b?url=${pageUrl}`, fetcher);
  
    return {
      co2: data?.c?.toFixed(2),
      isLoading: !error && !data,
      isError: error
    }
}

export { usePageCarbon };
