import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { HeadingContext } from './HeadingContext';
import { Text } from '../Text';

// inspiration https://www.tenon-ui.info/headings

const propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
const defaultProps = {
    className: '',
};

const Heading = forwardRef(({
    children,
    ...props
}, ref) => (
    <HeadingContext.Consumer>
        {(level) => {
            const H = `h${Math.min(level, 6)}`;

            return (
                <Text as={H} ref={ref} {...props}>
                    {children}
                </Text>
            );
        }}
    </HeadingContext.Consumer>
));

Heading.displayName = 'Heading';
Heading.propTypes = propTypes;
Heading.defaultProps = defaultProps;

export { Heading };
