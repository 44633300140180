import React from 'react';
import PropTypes from 'prop-types';

import { cx, capitalizeFirstLetter } from 'utility';
import * as styles from './Text.module.scss';

const propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    children: PropTypes.node,
    className: PropTypes.string,
    emphasis: PropTypes.oneOf(['', 'low', 'medium', 'high']),
    size: PropTypes.oneOf([undefined, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]),
};

const defaultProps = {
    as: 'p',
    children: null,
    className: '',
    emphasis: '',
    size: undefined,
};

function getClasses({ className, size, emphasis }) {
    const classes = cx(
        className,
        Number.isInteger(size) && styles[`textSize${size}`],
        emphasis !== '' && styles[`textEmphasis${capitalizeFirstLetter(emphasis)}`],
    );

    return classes || undefined;
}

function Text({
    as: El,
    children,
    className,
    emphasis,
    size,
    ...props
}) {
    const classes = getClasses({ className, size, emphasis });

    return (
        <El className={classes} {...props}>
            {children}
        </El>
    );
}

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export { Text };
